"use client";
import { useRef } from "react";
import { InstancedElementItem } from "types/element-item";
import { useDroppable, ClientRect } from "@dnd-kit/core";
import { InstansedElementChip } from "components/InstansedElementChip";
import { DroppableTypes } from "types/droppable-types";
import { Stage, Layer, Line } from "react-konva";
export function GameTable({
  instancedItems,
  draggedRect,
  canvasRef,
}: {
  instancedItems: InstancedElementItem[];
  draggedRect: ClientRect | null;
  canvasRef: React.RefObject<HTMLDivElement>;
}) {
  // const canvasRef = useRef<HTMLDivElement>(null);
  const { setNodeRef: tableRef } = useDroppable({
    id: `${DroppableTypes.Table}::`,
  });
  const maxDistance = 200;
  return (
    <div
      ref={tableRef}
      className="grow relative bg-dotted-spacing-5 bg-dotted-gray-200"
    >
      <div ref={canvasRef} className="absolute h-full">
        <Stage
          width={window.innerWidth}
          height={canvasRef.current?.clientHeight ?? 0}
        >
          <Layer>
            {instancedItems.map((from, index) =>
              instancedItems.slice(index).map((to) => {
                if (from.intancedId == to.intancedId) return null;
                return (
                  <RelationLine
                    fromId={from.intancedId}
                    toId={to.intancedId}
                    maxDistance={maxDistance}
                  />
                );
              }),
            )}
            {draggedRect &&
              instancedItems.map((to) => {
                const toId = to.intancedId;
                const toElement = document.getElementById(toId);
                if (!toElement) return null;
                const toRect = toElement.getBoundingClientRect();
                const { distance, color } = getColorByRects(
                  draggedRect,
                  toRect,
                  maxDistance,
                );
                if (distance > maxDistance) return null;
                return (
                  <Line
                    points={[
                      draggedRect.left + draggedRect.width / 2,
                      draggedRect.top + draggedRect.height / 2,
                      toRect.x + toRect.width / 2,
                      toRect.y + toRect.height / 2,
                    ]}
                    stroke={color}
                    strokeWidth={1}
                  />
                );
              })}
          </Layer>
        </Stage>
      </div>

      {instancedItems.map((element) => (
        <InstansedElementChip key={element.intancedId} element={element} />
      ))}
    </div>
  );
}
export function getColorByRects(
  from: ClientRect,
  to: ClientRect,
  maxDistance: number,
): { distance: number; color: string } {
  const distance = Math.sqrt(
    (from.left + from.width / 2 - (to.left + to.width / 2)) ** 2 +
      (from.top + from.height / 2 - (to.top + to.height / 2)) ** 2,
  );

  if (distance > maxDistance) return { distance, color: "transparent" };

  const delta = distance / maxDistance;

  return { distance, color: `rgba(0, 0, 0, ${(1 - delta * delta) * 0.5})` };
}
export function RelationLine({
  fromId,
  toId,
  maxDistance,
}: {
  fromId: string;
  toId: string;
  maxDistance: number;
}) {
  const fromElement = document.getElementById(fromId);
  const toElement = document.getElementById(toId);
  if (fromId == "overlay-item") {
    console.log({ fromElement, toElement });
  }
  if (!fromElement || !toElement) return null;
  const fromRect = fromElement.getBoundingClientRect();
  const toRect = toElement.getBoundingClientRect();
  const { distance, color } = getColorByRects(fromRect, toRect, maxDistance);
  if (distance > maxDistance) return null;
  return (
    <Line
      key={fromId + toId}
      points={[
        fromRect.x + fromRect.width / 2,
        fromRect.y + fromRect.height / 2,
        toRect.x + toRect.width / 2,
        toRect.y + toRect.height / 2,
      ]}
      stroke={color}
      strokeWidth={1}
    />
  );
}
