import { DraggableTypes } from "types/draggable-types";
import { ElementItem } from "types/element-item";
import { useDraggable } from "@dnd-kit/core";

export function ElementChip({
  element,
  selfDiscovered,
  overlay,
  onCreate,
}: {
  element: ElementItem;
  selfDiscovered: boolean;
  overlay?: boolean;
  onCreate?: () => void;
}) {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: `${DraggableTypes.Stored}:${element.itemId}:`,
  });

  return (
    <div ref={setNodeRef} {...listeners} {...attributes}>
      <div
        onClick={onCreate}
        id={overlay ? "overlay-item" : undefined}
        className={`${selfDiscovered ? "border border-yellow-400" : "border border-gray-400"} text-nowrap m-1 cursor-pointer rounded-[5px] flex select-none bg-instance hover:bg-hover w-auto min-h-8 gap-1 leading-8 text-sm py-0 px-2 text-black font-medium transition duration-100 touch-none`}
      >
        <div>{element.emoji}</div>
        <div className="capitalize ">{element.name.replace(" ", "\xa0")}</div>
      </div>
    </div>
  );
}
