import { DraggableTypes } from "types/draggable-types";
import { DroppableTypes } from "types/droppable-types";
import { InstancedElementItem } from "types/element-item";
import { useDraggable, useDroppable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { useCombinedRefs } from "@dnd-kit/utilities";

export function InstansedElementChip({
  element,
}: {
  element: InstancedElementItem;
}) {
  const draggableId = `${DraggableTypes.Instanced}:${element.itemId}:${element.intancedId}`;
  const droppableId = `${DroppableTypes.InstanceItem}:${element.itemId}:${element.intancedId}`;
  const {
    attributes,
    listeners,
    setNodeRef: draggableRef,
    transform,
    isDragging,
  } = useDraggable({ id: draggableId, disabled: element.isLoading });
  const { setNodeRef: droppableRef, isOver } = useDroppable({
    id: droppableId,
    disabled: element.isLoading,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
    left: element.coordinates.x,
    top: element.coordinates.y,
  };
  const combined = useCombinedRefs(draggableRef, droppableRef);
  const ref = isDragging ? draggableRef : combined;

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        ...style,
      }}
      {...listeners}
      {...attributes}
    >
      <div
        id={isDragging ? undefined : element.intancedId}
        className={`${
          isOver || isDragging ? "bg-casted" : "bg-instance"
        } max-h-[140px] text-nowrap m-1 cursor-pointer rounded-[5px] flex flex-nowrap select-none hover:bg-hover w-auto min-h-8 gap-1 leading-8 text-sm py-0 px-2 border border-gray-400 text-black font-medium transition duration-100 touch-none `}
      >
        <div>{element.emoji}</div>
        <div className="capitalize text-nowrap ">
          {element.name.replace(" ", "\xa0")}
        </div>
      </div>
    </div>
  );
}
