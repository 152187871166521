import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./Home";
import { TmaSDKLoader } from "components/TmaSDKLoader";

window.scrollTo(0, 100);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <TmaSDKLoader>
      <App />
    </TmaSDKLoader>
  </React.StrictMode>,
);
