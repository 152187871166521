import { retrieveLaunchParams } from "@tma.js/sdk";
import { ElementItem } from "types/element-item";

export async function mockCastPair(first: ElementItem, second: ElementItem) {
  const { initDataRaw } = retrieveLaunchParams();
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/pair?first=${first.itemId}&second=${second.itemId}`,
    {
      headers: { Authorization: `tma ${initDataRaw}` },
    },
  );
  if (!response.ok) {
    return null;
  }

  const result = await response.json();
  console.log(result);
  return {
    item: <ElementItem>result.item,
    isDiscover: <boolean>result.isDiscover,
  };
}
