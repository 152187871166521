"use client";

import { useCallback, useEffect, useRef, useState } from "react";
import {
  useViewport,
  useHapticFeedback,
  usePopup,
  useLaunchParams,
} from "@tma.js/sdk-react";
import { ElementItem, InstancedElementItem } from "types/element-item";
import { ElementChip } from "components/ElementChip";
import {
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverlay,
  DragStartEvent,
  ClientRect,
  useSensors,
  useSensor,
  PointerSensor,
} from "@dnd-kit/core";
import { DroppableTypes } from "types/droppable-types";
import { DraggableTypes } from "types/draggable-types";
import { mockCastPair } from "helpers/cast-elements";
import { v4 as uuidv4 } from "uuid";

import { GameTable } from "components/GameTable";
import { StorePanel } from "components/StorePanel";

export default function Home() {
  const haptic = useHapticFeedback();
  const viewport = useViewport();
  const popup = usePopup();
  const { initDataRaw } = useLaunchParams();
  const [storedItems, setStoredItems] = useState<ElementItem[]>([]);
  const [instancedItems, setInstancedItems] = useState<InstancedElementItem[]>(
    [],
  );
  const [draggedItem, setDraggedItem] = useState<ElementItem | null>(null);
  const [draggedRect, setDraggedRect] = useState<ClientRect | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const tableRef = useRef<HTMLDivElement>(null);

  const castItems = useCallback(
    async (first: ElementItem, second: ElementItem, newIntanceId: string) => {
      const result = await mockCastPair(first, second);
      if (!result) {
        popup.open({
          title: `Ошибка!`,
          message: `Ошибка при попытке скрестить элементы, попробуйте еще раз`,
          buttons: [{ type: "close" }],
        });
        setInstancedItems((instances) =>
          instances.filter((x) => x.intancedId !== newIntanceId),
        );
        return;
      }
      const { item: casted, isDiscover } = result;

      if (!storedItems.find((x) => x.itemId == casted.itemId)) {
        setStoredItems([casted, ...storedItems]);
      }
      haptic.impactOccurred("medium");
      setInstancedItems((instances) =>
        instances.map((x) =>
          x.intancedId === newIntanceId
            ? { ...x, ...casted, isLoading: false }
            : x,
        ),
      );
      if (isDiscover) {
        haptic.notificationOccurred("success");
        popup.open({
          title: `Открыт новый элемент!`,
          message: `Вы раньше всех открыли:\n\n${casted.emoji}${casted.name[0].toUpperCase() + casted.name.slice(1)}`,
          buttons: [{ type: "ok" }],
        });
      }
      return casted;
    },
    [storedItems, setStoredItems],
  );
  const handleDragMove = useCallback(
    (event: DragMoveEvent) => {
      const { active } = event;
      setDraggedRect(active.rect.current.translated);
    },
    [storedItems, setDraggedItem],
  );
  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      const { active } = event;
      // setDraggedRect();
      console.log({ rect: active.rect });
      const [draggableType, dragId, dragInstanceId] = active.id
        .toString()
        .split(":");
      if (draggableType == DraggableTypes.Instanced) {
        const elementRect = document
          .getElementById(dragInstanceId)
          ?.getBoundingClientRect();
        if (elementRect) {
          setDraggedRect(elementRect);
        }
      }
      // console.log(dragId);
      setDraggedItem(storedItems.find((x) => x.itemId == dragId) ?? null);
    },
    [storedItems, setDraggedItem],
  );

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      const { active, over, delta } = event;
      // console.log({
      //   active,
      //   "active.rect.current.translated": active.rect.current.translated,
      //   over,
      //   draggedItem,
      // });
      if (!active || !active.rect.current.translated || !over || !draggedItem) {
        console.log("invalid drag");
        return;
      }
      let activeRect = active.rect.current.translated;
      const [draggableType, , dragInstanceId] = active.id.toString().split(":");
      const [droppableType, , dropInstanceId] = over.id.toString().split(":");

      if (draggableType == DraggableTypes.Instanced) {
        const draggableIndex = instancedItems.findIndex(
          (x) => x.intancedId == dragInstanceId,
        );
        if (!instancedItems[draggableIndex]) {
          return;
        }
        if (droppableType == DroppableTypes.Store) {
          setInstancedItems((items) =>
            items.filter((x) => x.intancedId != dragInstanceId),
          );
        } else if (droppableType == DroppableTypes.Table) {
          setInstancedItems((items) => {
            const instanced = {
              ...items[draggableIndex]!,
              coordinates: {
                x: items[draggableIndex]!.coordinates.x + delta.x,
                y: items[draggableIndex]!.coordinates.y + delta.y,
              },
            };
            return [
              ...items.slice(0, draggableIndex),
              instanced,
              ...items.slice(draggableIndex + 1),
            ];
          });
        } else if (droppableType == DroppableTypes.InstanceItem) {
          if (dropInstanceId == dragInstanceId) {
            return;
          }
          const droppableIndex = instancedItems.findIndex(
            (x) => x.intancedId == dropInstanceId,
          );
          if (instancedItems[droppableIndex].isLoading) {
            return;
          }
          const newInstanced: InstancedElementItem = {
            itemId: draggedItem.itemId,
            emoji: "⏳",
            name: "...",
            discovererUserId: "base",
            intancedId: uuidv4(),
            isLoading: true,
            coordinates: {
              x: over.rect.left,
              y: over.rect.top,
            },
          };
          castItems(
            draggedItem,
            instancedItems[droppableIndex]!,
            newInstanced.intancedId,
          );
          setInstancedItems([
            ...instancedItems.filter(
              (x) =>
                x.intancedId != dropInstanceId &&
                x.intancedId != dragInstanceId,
            ),
            newInstanced,
          ]);
        }
      } else if (draggableType == DraggableTypes.Stored) {
        if (droppableType == DroppableTypes.Table) {
          setInstancedItems((items) => [
            ...items,
            {
              ...draggedItem,
              intancedId: uuidv4(),
              coordinates: {
                x: activeRect.left,
                y: activeRect.top,
              },
            },
          ]);
        } else if (droppableType == DroppableTypes.InstanceItem) {
          const droppableIndex = instancedItems.findIndex(
            (x) => x.intancedId == dropInstanceId,
          );
          if (instancedItems[droppableIndex].isLoading) {
            return;
          }
          const newInstanced: InstancedElementItem = {
            itemId: draggedItem.itemId,
            emoji: "⏳",
            name: "...",
            discovererUserId: "base",
            intancedId: uuidv4(),
            isLoading: true,
            coordinates: {
              x: over.rect.left,
              y: over.rect.top,
            },
          };
          castItems(
            draggedItem,
            instancedItems[droppableIndex]!,
            newInstanced.intancedId,
          );
          setInstancedItems([
            ...instancedItems.slice(0, droppableIndex),
            newInstanced,
            ...instancedItems.slice(droppableIndex + 1),
          ]);
        }
      }
      setDraggedItem(null);
      setDraggedRect(null);
    },
    [draggedItem, instancedItems, castItems],
  );

  const handleCreate = useCallback((item: ElementItem) => {
    console.log(1);
    if (tableRef.current == null) return;
    const rect = tableRef.current!.getBoundingClientRect();
    setInstancedItems((items) => [
      ...items,
      {
        ...item,
        intancedId: uuidv4(),
        coordinates: {
          x: rect.width / 2 + (Math.random() * 100 - 110),
          y: rect.height / 2 + (Math.random() * 100 - 50),
        },
      },
    ]);
  }, []);
  useEffect(() => {
    function onTouchEvent(e: TouchEvent) {
      if (draggedItem) e.preventDefault();
    }
    viewport.expand();
    window.addEventListener("touchmove", onTouchEvent, {
      passive: false,
    });
    return () => {
      window.removeEventListener("touchmove", onTouchEvent);
    };
  }, [draggedItem, viewport]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/user-info`, {
      headers: { Authorization: `tma ${initDataRaw}` },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUserId(data.userId as string);
        setStoredItems(data.items as ElementItem[]);
      })
      .catch((error) => console.log(error));
  }, [initDataRaw]);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 1 },
    }),
  );
  return userId ? (
    <DndContext
      onDragMove={handleDragMove}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div
        className="w-full flex flex-col justify-stretch bg-white overflow-hidden"
        style={{ height: viewport.stableHeight }}
      >
        <GameTable
          canvasRef={tableRef}
          instancedItems={instancedItems}
          draggedRect={draggedRect}
        />
        <StorePanel
          storedItems={storedItems}
          userId={userId}
          onCreate={handleCreate}
        />
      </div>
      <DragOverlay dropAnimation={null}>
        {draggedItem ? (
          <ElementChip
            element={draggedItem}
            selfDiscovered={false}
            overlay={true}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  ) : (
    <div className="w-full mt-10 text-center font-bold text-lg align-middle">
      Загрузка..
    </div>
  );
}
