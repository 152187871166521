"use client";
import { ElementItem } from "types/element-item";
import { ElementChip } from "components/ElementChip";
import { useDroppable } from "@dnd-kit/core";
import { DroppableTypes } from "types/droppable-types";

export function StorePanel({
  storedItems,
  userId,
  onCreate,
}: {
  storedItems: ElementItem[];
  userId: string;
  onCreate: (item: ElementItem) => void;
}) {
  const { setNodeRef: storeRef } = useDroppable({
    id: `${DroppableTypes.Store}::`,
  });
  return (
    <div
      ref={storeRef}
      className="bg-white border border-t-gray-500 flex flex-wrap p-1 overflow-y-scroll max-h-[30%] !touch-pan-y"
    >
      {storedItems.map((element) => (
        <ElementChip
          key={element.itemId}
          element={element}
          selfDiscovered={element.discovererUserId == userId}
          onCreate={() => onCreate(element)}
        />
      ))}
    </div>
  );
}
